.table-cell-actions-overlayPlaylistsTracksAdd,
.table-cell-actions-overlayPlaylistsTracksAddMulti,
.table-cell-actions-overlayPlaylistsTracksAddSearch {
  .icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    left: auto;
    margin: 0;
    position: relative;
    top: auto;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.table-cell-actions-overlayPlaylistsTracksAddMulti {
  .icon-success {
    cursor: default;
  }
}
.container-overlayPlaylistsTracksAddSearch-wrapper{
  .typeahead-wrapper{
    padding:0;
    .typeahead-input{
      height: 39px;
      padding: 7px 10px 7px 33px;
    }
    .icon-ios-search{
      top:9px;
      left:6px;
    }
    .icon-ios-close{
      right:6px;
      top:-34px;
    }
  }
}