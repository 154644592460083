.button{
  background-color: transparent;
  padding:10px 20px;
  margin:5px 0;
  border:1px solid $colour-background-light;
  outline:none;
  border-radius:4px;
  &.button-block{
    display: block;
    width:100%;
  }
  &:hover{
    border:1px solid $colour-text-link;
    background-color: $colour-background-light;
  }
  &:disabled{
    //color: $colour-text-disabled;
    border:1px solid $colour-error;
    background-color: $colour-background-mid;
  }
  &:disabled:hover {
    cursor: not-allowed;
    border:1px solid $colour-error;
    background-color: $colour-background-mid;
  }
  &.button-main{
    background-color: $colour-text-link;
    &:hover{
      border:1px solid $colour-text-link;
      background-color: $colour-background-light;
    }
  }
  &.button-small{
    font-size: 12px;
    padding:6px 9px;
    margin:0;
  }
  &.button-switch{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button-name{
      width:100%;
    }
  }
  &.button-switch-active{
    border-color: $colour-success;
    .icon{
      color: $colour-success;
    }
  }
}
