.venue-contacts__add-new {
  margin-bottom: 1rem;

  .button:first-child {
    margin-right: 1rem;
  }
}

.typeahead-wrapper-overlayContactsAddToVenue {
  background-color: $colour-background-mid;
  padding: 5px 20px;
}