.container .container-overlay-schedule-saved{
  .header{
    font-size: 15px;
    padding-right: 48px;
  }

  .overlay-schedule-saved__close-btn {
    cursor: pointer;
    font-size: 12px;
    margin: 0;
    position: absolute;
    right: 4px;
    top: 0;
  }

  .container-overlay-schedule-save-wrapper{
    height:100%;
    overflow-y:scroll;

    .schedule-saved-overlay__wrapper {
      display: flex;

      .schedule-saved-overlay {
        margin: 10px;
        padding: 10px 20px;
        border-radius: 4px;
        border: 1px solid $colour-text-link;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        .icon-wrapper {
          .icon {
            color: $colour-success;
          }
        }

        &:hover {
          border: 1px solid $colour-accent;
          background-color: $colour-background-light;
        }

        &.selected:not(:hover) {
          border: 1px solid $colour-success;
        }
      }

      .schedule-saved-overlay__delete {
        display: flex;
        align-items: center;

        .icon {
          cursor: pointer;
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}
