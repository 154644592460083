.contact-card {
  background-color: $colour-background-mid;
  border: 1px solid $colour-background-light;
  border-radius: 4px;
  color: $colour-text;
  margin: 0 20px 2rem 0;

  padding: 1rem;
  width: 100%;

  @media only screen and (min-width: 900px) {
    width: calc(50% - 20px);
  }

  @media only screen and (min-width: 1200px) {
    width: calc(33% - 20px);
  }

  .contact-card__item {
    width: 100%;
  }

  .contact-card__item--notes {
    margin-bottom: 1rem;

    p, textarea {
      min-height: 150px;
      outline: none;
      resize: none;
    }
  }

  .contact-card__item-cta {
    text-align: right;

    .button {
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  h4 {
    color: $colour-text-heading;
    font-size: 14px;
    margin: 0 0 .25rem 0;
  }

  p {
    min-height: 38px;
    margin: 0;
  }

  textarea {
    background-color: #193c52;
    border: 1px solid #112938;
    padding: 10px;
    width: 100%;
  }
}

.contact-card--primary {
  border: 1px solid $colour-accent-alt;

  .icon-wrapper {
    .icon-star {
      color: $colour-accent-alt;
      height: 32px;
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;

    }
  }
}

.contact-card--archive {
  opacity: .5;
}