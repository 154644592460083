.form-select-wrapper{
  margin: 15px 0;
  .form-select{
    background-color: $colour-background-light;
    cursor: pointer;
    outline: none;
    border:1px solid $colour-background-mid;
    padding: 6px 10px;
    option{
      color: $colour-text;
    }
  }

  .form-select__error-message {
    color: $colour-error;
    font-size: 13px;
    margin-bottom: 0;
  }
}
