.container-notes {
  .notes__controls {
    margin-bottom: 1rem;

    .filter-wrapper {
      background-color: $colour-background-mid;
      border-radius: 4px;
      max-width: 800px;
      margin-top: .75rem;
      padding: 5px 20px;
    }
  }

  .note {
    background-color: $colour-background-mid;
    border: 1px solid $colour-background-light;
    border-radius: 4px;
    margin: 1rem 0;
    max-width: 800px;
    padding: 1rem;
    position: relative;

    a {
      color: white;
    }
  }

  .note__text {
    font-size: 18px;
    padding-right: 30px;
    white-space: pre-line;
  }

  .note__info {
    font-size: 14px;
  }

  .note__delete {
    position: absolute;
    top: 10px;
    right: 10px;

    .icon {
      cursor: pointer;
      height: 26px;
      width: 26px;
    }
  }

  .note__loading {
    padding: 3rem;
  }

  .note--deleting {
    opacity: .5;
  }
}