.container.container-businessContacts {
  display: block;

  .business-contacts__add-new {
    margin-bottom: 1rem;

    .button:first-child {
      margin-right: 1rem;
    }
  }

  .business-contacts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.typeahead-wrapper-overlayContactsAddToBusiness {
  background-color: $colour-background-mid;
  padding: 5px 20px;
}